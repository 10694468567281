import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Container from './Container';
import PageContext from '../context';
import Toggle from './Toggle';

const StyledHeader = styled.header`
  width: 100%;
  border-top: 4px solid #4299e1;
  box-shadow: ${props => (props.scrolled ? '0 0 10px -2px #a0aec0' : '')};
  background: #fff;
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const WhiteOverlay = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inline;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    width: 100vh;
    height: 100vh;
    z-index: 1;
  }
`;

const Ul = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;

  @media (max-width: 768px) {
    top: 0;
    right: 0;
    width: 0;
    position: fixed;
    display: block;
    background-color: #fff;
    overflow-x: hidden;
    height: 100vh;
    visibility: hidden;
    transition: 0.2s ease-out;

    ${props => {
      if (props.showmenu) {
        return `
        visibility: visible; 
        width: 50%;
        transition: width 0.3s, visibility 0.3s ease-in-out;
        z-index: 9999;
      `;
      }
      return ``;
    }}
  }
`;

const Li = styled.li`
  margin: 0 10px;
`;

// eslint-disable-next-line react/prop-types
const StyledLink = styled(Link).attrs(props => ({
  className: `block px-4 py-${
    props.scrolled ? 8 : 10
  } uppercase text-md text-blue-700 font-medium hover:font-semibold`,
  activeClassName: 'font-semibold',
}))`
  transition: all 100ms ease-in;
`;

const Header = () => {
  const { isScrolled } = useContext(PageContext);
  const [showMenu, setShowMenu] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-final-2.png" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledHeader scrolled={+isScrolled} className="w-full">
      <Container>
        <StyledNav showmenu={+showMenu}>
          {showMenu && <WhiteOverlay onClick={() => setShowMenu(false)} />}
          <Link to="/">
            <img
              className="w-40 mx-3 m-0"
              src={data.logo.publicURL}
              alt="logo-mv"
            />
          </Link>
          <Ul showmenu={+showMenu}>
            <Li>
              <StyledLink scrolled={+isScrolled} to="/">
                Home
              </StyledLink>
            </Li>
            {/* <Li>
              <StyledLink scrolled={+isScrolled} to="/services">
                Services
              </StyledLink>
            </Li> */}
            <Li>
              <StyledLink scrolled={+isScrolled} to="/products">
                Products
              </StyledLink>
            </Li>
            <Li>
              <StyledLink scrolled={+isScrolled} to="/contact">
                Contact
              </StyledLink>
            </Li>
          </Ul>
          <Toggle setShowMenu={setShowMenu} showmenu={showMenu} />
        </StyledNav>
      </Container>
    </StyledHeader>
  );
};

export default Header;
