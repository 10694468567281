import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaWhatsapp,
} from 'react-icons/fa';
import styled from 'styled-components';

import Container from './Container';

const StyledFooter = styled.footer`
  padding: 20px 0;
  background-color: #3182ce;
  color: #fff;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-white.png" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledFooter>
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="px-5">
            <img
              alt="logo"
              className="w-32 m-0 p-0"
              src={data.logo.publicURL}
            />
            <p>
              One of the fastest growing metal trading companies in the Middle
              East.
            </p>
          </div>
          <div className="md:px-5 flex md:justify-center">
            <ul className="text-left">
              <li>
                <Link className="hover:underline" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="hover:underline" to="/products">
                  Products
                </Link>
              </li>
              <li>
                <Link className="hover:underline" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-sm">
            <ul>
              <li className="pr-2">
                <FaEnvelope className="inline-block text-xl" />
                <span className="ml-2">Sales@metalsvalley.com</span>
              </li>
              <li className="pr-2">
                <FaPhone className="inline-block text-xl" />
                <span className="ml-2">
                  (Tel)
                  <span className="font-sans"> 00971 6 7314159</span>
                </span>
              </li>
              <li className="pr-2">
                <FaWhatsapp className="inline-block text-xl" />
                <span className="ml-2">
                  (Mobile/WhatsApp)
                  <span className="font-sans"> 00971 56 64 66401</span>
                </span>
              </li>
              <li className="pr-2">
                <FaMapMarkerAlt className="inline-block text-xl" />
                <span className="ml-2">
                  Business center, Al Shmookh building, UAQ, UAE
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
