import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children }) => {
  return <div className="w-full mx-auto xl:w-10/12 md:w-11/12">{children}</div>;
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Container;
